import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 6; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/06-nov-23-4/pic${i}.jpg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function ShapeOMania() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Shape-O-Mania Activity</h1>

        <p>
          Chinmaya Vidyalaya conducted a special activity to explain the concept
          of Shapes to Pre-Primary students.
          <br />
          <br />
          Learning about shapes is vital to future learning as it helps children
          recognise and organise visual information. It also improves their
          reading skills and comprehension of STEM subjects.
          <br />
          <br />
          The learners were taught about the shapes using cut-outs and
          encouraged to identify and sort them.
          <br />
          <br />
          They had fun making trains with interesting shapes. It was a wonderful
          way to channel their creativity.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
